.images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  transition: opacity 0.12s;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1023px) {
    flex-direction: row;
  }
}

.image {
  display: block;
  width: 100%;
  border-radius: 3px;

  @media (max-width: 1023px) {
    width: auto;
    height: 120px;
  }
}

.modalImages {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.modalImage {
  display: block;
  max-width: 100%;
  border-radius: 3px;
}

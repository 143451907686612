.heading {
  padding: 0 0 96px;
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 28px;
  }
}

.content {
  max-width: 840px;
  margin: 0 auto;
  padding: 48px 48px 96px;
  border-bottom: 1px solid #20252c;

  @media (max-width: 767px) {
    padding: 24px 24px 64px;
  }
}

.position {
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 48px;
  }

  &:hover h4 {
    text-decoration: underline;
  }

  h4 {
    padding-bottom: 8px;
    font-size: 22px;
    color: white;
    opacity: 1;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: white;
    opacity: 0.6;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.hero {
  margin: 0 auto;
  padding: 0 48px 160px;
  max-width: 1000px;

  h1 {
    font-size: 64px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 32px;
  }

  p {
    font-size: 20px;
    color: white;
    opacity: 0.6;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 24px 120px;
    h1 {
      font-size: 32px !important;
    }
    p {
      font-size: 16px;
    }
  }
}

.centerVideoCont {
  margin-top: 48px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.centerVideoInner {
  position: relative;
  padding-bottom: 56.2%;
}

.centerVideoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  border-radius: 4px;
}

.heroContent {
  margin-top: 36px;
  display: flex;
  align-items: center;
  column-gap: 80px;

  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 48px;
    align-items: flex-start;

    & > div:last-child {
      max-width: 100% !important;
    }
  }
}

.section {
  max-width: 1150px;
  margin: 0 auto;
  padding: 200px 48px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 60%;
  align-items: center;
  gap: 72px;

  img {
    display: block;
    max-width: 100%;
    min-width: 0;
    border-radius: 3px;
  }

  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: #101216;
  }

  p {
    font-size: 20px;
    color: #101216;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: flex-start;

    img {
      max-width: 280px;
    }
  }
  @media (max-width: 767px) {
    padding: 120px 24px;
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 16px;
    }
  }
}

.sectionReverse {
  grid-auto-columns: 60% 1fr;

  h2 {
    color: white;
  }

  p {
    color: white;
    opacity: 0.6;
  }
}

.learnMoreButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  height: 48px;
  padding: 0 24px;
  background: #23272f;
  border-radius: 8px;
  font-size: 16px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.12s;

  &:hover:not(:active) {
    transform: translateY(-2px);
  }
}

.list {
  padding-top: 24px;
  padding-left: 14px;
  font-size: 14px;
  color: white;
  opacity: 0.6;

  li {
    line-height: 1.6;

    &:not(last-child) {
      margin-bottom: 8px;
    }
  }
}

.notFound {
  padding: 48px;
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  opacity: 0.2;
}

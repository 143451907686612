/* COLOURS */

$colour-cyan: #9fe870;
$colour-white: #ffffff;
$colour-black: #000000;

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

/* GLOBE */

.globe {
  position: relative;
  width: 100%;
  height: 600px;
  padding-top: 200px !important;

  @media (max-width: 768px) {
    height: 300px;
  }
}

.globe .globe-list {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  list-style: none;
}

$dotSize: 16px;
$dotSizeLarge: $dotSize + ($dotSize / 2);

.globe .globe-list {
  opacity: 0;
  @include transition(opacity 3s cubic-bezier(0.175, 0.885, 0.32, 1.275));
}

.globe .globe-list.active {
  opacity: 1;
}

.globe .globe-list > li {
  opacity: 0.4;
  position: absolute;
  margin-left: -47px; // ???? fix dots position -($dotSize / 2);
  margin-top: -($dotSize / 2);
  width: $dotSize;
  height: $dotSize;
  border-radius: 50%;
  background: $colour-cyan;
  @include transition(opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1.275));
}

.globe .globe-list > li.active {
  opacity: 1;
  margin-left: -50px; // ??? fix dots position -($dotSizeLarge / 2);
  margin-top: -($dotSizeLarge / 2);
  width: $dotSizeLarge;
  height: $dotSizeLarge;
}

.globe .globe-list > li:before {
  content: '';
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -($dotSize / 2);
  margin-top: -($dotSize / 2);
  width: $dotSize;
  height: $dotSize;
  border-radius: 50%;
  background: $colour-cyan;
  @include animation(2s pulse infinite linear);
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -moz-transform: scale(2);
  }
}

@-ms-keyframes pulse {
  0% {
    -ms-transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -ms-transform: scale(2);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -o-transform: scale(2);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.globe .globe-list > li.active:before {
  margin-left: -($dotSizeLarge / 2);
  margin-top: -($dotSizeLarge / 2);
  width: $dotSizeLarge;
  height: $dotSizeLarge;
}

.globe .globe-list > li.active,
.globe .globe-list > li.active:before {
  background: #9fe870;
}

.globe .globe-list .text {
  position: absolute;
  opacity: 0.8;
  right: $dotSize + 5px;
  top: 50%;
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-align: right;
  text-shadow: -1px -1px 0 $colour-black, 1px -1px 0 $colour-black, -1px 1px 0 $colour-black,
    1px 1px 0 $colour-black;
  color: $colour-white;
  white-space: nowrap;
  @include transform(translateY(-50%));
}

.globe .globe-list > li.active .text {
  opacity: 1;
  right: $dotSizeLarge + 5px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.globe .globe-canvas {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
}

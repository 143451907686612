.navbar {
  z-index: 8;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px;
  background: #101216;
}

.logo {
  color: #fff;
}

.navLinks {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.navLink {
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  a {
    color: #fff;
  }

  &:hover {
    .dropdownCont {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
    }
  }
}

.dropdownCont {
  z-index: 10;
  transition: all 0.125s;
  opacity: 0;
  transform: translateX(-10px);
  pointer-events: none;
  position: absolute;
  top: 100%;
  right: -48px;
  padding-top: 24px;
}

.dropdown {
  background: #20252c;
  border-radius: 8px;
  padding: 16px;

  & > * {
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 12px;
    border-radius: 6px;

    &:hover {
      color: #9fe870;
      background: #101216;
    }
  }
}

.mobileDropdown {
  padding: 24px;
}

.mobileTitle {
  padding: 32px 0 8px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  opacity: 0.6;
  text-transform: uppercase;

  &:first-of-type {
    padding-top: 0;
  }
}

.mobileLink {
  padding: 8px 0;
  display: block;
  font-size: 14px;
  color: white;
}

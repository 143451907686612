.globeContainer {
  padding: 48px;

  @media (max-width: 767px) {
    padding: 48px 24px;
  }
}

.heading {
  padding-bottom: 48px;
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.content {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 48px 96px;
  border-bottom: 1px solid #20252c;

  @media (max-width: 767px) {
    padding: 0 24px 96px;
  }
}

.paragraph {
  font-size: 18px;
  color: white;
  opacity: 0.6;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.largeText {
  font-size: 24px;
  color: white;
  opacity: 1;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.subheading {
  text-align: center;
  font-size: 28px;
  color: white;
  opacity: 1;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.companyGrid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 48px 96px;
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 96px;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid #20252c;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 1023px) {
    gap: 64px;
  }
}

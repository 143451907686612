.tokens {
  display: flex;
  align-items: center;
  white-space: nowrap;
  column-gap: 40px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

.token {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  min-width: 480px;
  height: 480px;
  border-radius: 24px;
  padding: 0 48px;
  color: #101216;
  background: #9fe870;

  .graph {
    color: #9fe870;
  }
  polyline {
    stroke: #9fe870;
  }

  &:nth-child(even) {
    background: #9e70e8;

    .graph {
      color: #9e70e8;
    }
    polyline {
      stroke: #9e70e8;
    }
  }

  @media (max-width: 767px) {
    width: 312px;
    min-width: 312px;
    height: auto;
    padding: 36px 24px;
  }
}

.name {
  font-size: 24px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.price {
  padding: 32px 0 12px;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 28px;
  }
}

.change {
  padding-bottom: 12px;
  font-size: 28px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.marketCap {
  padding-bottom: 24px;
  font-size: 28px;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.graph {
  align-self: flex-start;
  border-radius: 8px;
  padding: 8px 12px;
  background: #101216;
  color: #fff;
}

.graphWeek {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 8px;
}

.button {
  position: absolute;
  bottom: 16px;
  left: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 999px;
  color: #fff;
  background: #23272f;
  cursor: pointer;
  transition: transform 0.12s;

  &:hover:not(:active) {
    transform: translateY(-2px);
  }

  &:last-child {
    left: 106px;
  }

  @media (max-width: 767px) {
    left: 0px;
    bottom: -72px;

    &:last-child {
      left: 58px;
    }
  }
}

body {
  font-weight: 400;
  color: white;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
}

p {
  line-height: 1.6;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
}

h1,
h2,
h3 {
  font-family: 'Mulish', sans-serif;
  font-weight: 1000;
  text-transform: uppercase;
}

.grecaptcha-badge {
  display: none !important;
}

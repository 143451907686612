.tickers {
  width: 100%;
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  align-items: center;
  justify-items: center;
}

.ticker {
  position: relative;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
  font-variant-numeric: tabular-nums;
  opacity: 0.45;
}

.flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.graph {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

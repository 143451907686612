.canvas {
  width: 100%;
  height: 100%;
}

.live {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.tag {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  padding: 4px 8px;
  background: #ab3e3a;
  font-weight: 800;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: uppercase;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: #fff;
  animation: blink 1s linear infinite;
}

.label {
  opacity: 0.6;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tabs {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(255, 255, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;

  &.active {
    background: white;
    color: #101216;
  }

  &:hover {
    border: 2px solid white;
  }
}

.heading {
  padding: 48px;
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    padding: 0 24px 48px;
  }
}

.content {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 48px 96px;
  border-bottom: 1px solid #20252c;

  @media (max-width: 767px) {
    padding: 0 24px 96px;
  }

  p {
    margin-bottom: 24px;
    font-size: 16px;
    color: white;
    opacity: 0.6;
  }

  h3 {
    margin: 48px 0 24px;
    font-size: 20px;
    color: white;
    font-weight: 700;
    text-transform: none;
  }
}

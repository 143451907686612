.carousel {
  overflow: hidden;
  white-space: nowrap;
  height: 320px;
}

.carouselInner {
  height: 100%;
  display: flex;
}

.carouselItem {
  flex-shrink: 0;
}

.nft {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: #101216;
}

.imageWrapper {
  display: block;
  position: relative;
  padding-bottom: 100%;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    object-fit: cover;
  }
}

.name {
  font-size: 14px;
  color: white;
  font-weight: 700;
  margin-top: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.time {
  font-size: 12px;
  color: white;
  opacity: 0.6;
  margin-top: 10px;
}

.price {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-top: 10px;
}

.heading {
  text-align: center;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  padding: 48px;

  @media (max-width: 767px) {
    padding: 48px 24px;
    font-size: 32px;
  }
}

.form {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 48px 96px;
  border-bottom: 1px solid #20252c;

  @media (max-width: 767px) {
    padding: 0 24px 96px;
  }
}

.flex {
  display: flex;
  gap: 16px;

  & > div {
    width: calc(50% - 8px);
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.label {
  display: block;
  opacity: 0.6;
  padding: 24px 0 8px;
}

.error {
  padding-top: 8px;
  font-size: 12px;
  color: #ff4949;
}

.success {
  margin-top: 24px;
  border-radius: 8px;
  background: rgba(#9fe870, 0.1);
  color: #9fe870;
  padding: 16px;
  font-size: 14px;
  text-align: center;
}

.submitButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  height: 48px;
  padding: 0 24px;
  color: #101216;
  background: #9fe870;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: transform 0.12s;

  &:hover:not(:active) {
    transform: translateY(-2px);
  }

  &:disabled {
    opacity: 0.6;
  }
}

.subheading {
  text-align: center;
  font-size: 28px;
  color: white;
  opacity: 1;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.companyGrid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 48px 96px;
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 96px;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid #20252c;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 1023px) {
    gap: 64px;
  }
}

.footer {
  margin: 0 auto;
  width: 100%;
  max-width: 1240px;
  padding: 120px 48px;

  @media (max-width: 767px) {
    padding: 120px 24px;
  }
}

.logo {
  display: inline-block;
  margin-bottom: 40px;
  color: #fff;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  column-gap: 120px;
  row-gap: 48px;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  a {
    font-size: 16px;
    color: white;
    opacity: 0.5;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}

.bottom {
  border-top: 1px solid #20252c;
  padding-top: 40px;
  margin-top: 40px;
  font-size: 12px;
  color: white;
  opacity: 0.5;
}

.disclaimer {
  padding-top: 40px;
  font-size: 10px;
  opacity: 0.6;

  p:not(:last-child) {
    padding-bottom: 16px;
  }
}

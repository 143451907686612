.hero {
  position: relative;
  padding: 0 0 180px;

  @media (max-width: 767px) {
    padding: 0 0 120px;
  }
}

.videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  z-index: 0;
  border: 0;
  pointer-events: none;
}

.heroContent {
  position: relative;
  z-index: 2;
  padding: 0 48px;

  @media (max-width: 767px) {
    padding: 0 24px;
  }
}

.heading {
  margin: 0 auto;
  padding-top: 48px;
  font-size: 64px;
  text-transform: uppercase;
  color: white;
  max-width: 855px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.heroDescription {
  margin: 0 auto;
  max-width: 860px;
  padding-top: 32px;
  text-align: center;
  font-size: 20px;
  color: white;
  opacity: 0.6;
}

.centerVideoCont {
  margin: 60px auto 0;
  max-width: 900px;
}

.centerVideoInner {
  position: relative;
  padding-bottom: 56.55%;
}

.centerVideoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  border-radius: 4px;
}

.solutionAnalysis {
  max-width: 1240px;
  margin: 0 auto;
  padding: 205px 48px;

  & > div:first-child {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 240px;
    align-items: center;
    gap: 72px;
  }

  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: white;
  }

  p {
    font-size: 20px;
    color: white;
    opacity: 0.6;
  }

  .lastUpdated {
    font-size: 14px;
    color: white;
    opacity: 0.6;
    padding-top: 24px;
    text-align: center;
  }

  @media (max-width: 1023px) {
    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      margin-bottom: 48px;
    }
    button {
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    padding: 120px 24px;

    h2 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }
}

.solutionDynamic {
  max-width: 1240px;
  margin: 0 auto;
  padding: 205px 48px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 510px;
  align-items: center;
  gap: 72px;

  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: #101216;
  }

  p {
    font-size: 20px;
    color: #101216;
  }

  .list {
    opacity: 1;
    color: #101216;
  }

  .iframeContainer {
    overflow: hidden;
    border-radius: 4px;
    width: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    height: 450px;
    border: none;
    transform: scale(1.015);
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  @media (max-width: 767px) {
    padding: 120px 24px;

    h2 {
      font-size: 32px;
    }
    iframe {
      height: 270px;
    }
  }
}

.solutionSurveilance {
  max-width: 1240px;
  margin: 0 auto;
  padding: 195px 48px 120px;

  h2 {
    padding-bottom: 56px;
    font-size: 100px;
    color: white;
  }
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    align-items: center;
    gap: 96px;
    min-width: 0;
  }
  img {
    display: block;
    max-width: 100%;
  }
  p {
    font-size: 24px;
    color: white;
    opacity: 0.6;
  }

  @media (max-width: 1023px) {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }
    h2 {
      font-size: 64px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    padding: 120px 24px 80px;

    h2 {
      font-size: 32px;
    }
  }
}

.solutionFrequency {
  max-width: 1240px;
  margin: 0 auto;
  padding: 120px 48px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 500px 1fr;
  align-items: center;
  gap: 72px;

  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: white;
  }

  p {
    font-size: 20px;
    color: white;
    opacity: 0.6;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    & > div:first-child {
      order: 2;
    }
    & > div:last-child {
      order: 1;
    }
  }
  @media (max-width: 767px) {
    padding: 80px 24px;

    h2 {
      font-size: 32px;
    }
  }
}

.matrixModalContainer {
  background: #101216;
  padding: 16px;
  border-radius: 8px;
}

.solutionLaunching {
  max-width: 1240px;
  margin: 0 auto;
  padding: 120px 0 205px 48px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 460px 1fr;
  align-items: center;
  gap: 96px;

  & > div:last-child {
    min-width: 0;
  }
  img {
    width: 1000px;
  }
  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: white;
    max-width: 460px;
  }
  p {
    font-size: 20px;
    color: white;
    opacity: 0.6;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  @media (max-width: 767px) {
    padding: 80px 0 120px 24px;

    h2 {
      font-size: 32px;
    }
  }
}

.solutionNfts {
  max-width: 1240px;
  margin: 0 auto;
  padding: 205px 48px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 460px 1fr;
  align-items: center;
  gap: 96px;

  h2 {
    padding-bottom: 24px;
    font-size: 48px;
    color: #101216;
  }
  p {
    font-size: 20px;
    color: #101216;
  }
  .list {
    opacity: 1;
    color: #101216;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 48px;

    & > div:first-child {
      order: 2;
    }
    & > div:last-child {
      order: 1;
    }
  }
  @media (max-width: 767px) {
    padding: 120px 24px;

    h2 {
      font-size: 32px;
    }
  }
}

.learnMoreButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  height: 48px;
  padding: 0 24px;
  background: #23272f;
  border-radius: 8px;
  font-size: 16px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.12s;

  &:hover:not(:active) {
    transform: translateY(-2px);
  }
}

.list {
  padding-top: 24px;
  padding-left: 14px;
  font-size: 14px;
  color: white;
  opacity: 0.6;

  li {
    line-height: 1.6;

    &:not(last-child) {
      margin-bottom: 8px;
    }
  }
}

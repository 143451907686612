.scrollOverlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.clickableOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 48px;
  background: rgba(0, 0, 0, 0.6);

  @media (max-width: 767px) {
    padding: 0 16px;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
  border-radius: 10px;
  animation: scaleIn 0.15s 1 both ease-out;
  background: #101216;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.04),
    inset 0px -4px 0px rgba(0, 0, 0, 0.25);
}
